import { FC, useState } from "react";
import { FileSelect } from "features/entry/components/applicationDocument/FileSelect";
import { Box } from "@mui/material";
import { PersonalHistoryType } from "types/entryTypes";

export const PersonalHistory: FC<PersonalHistoryType> = (props) => {
	const {setValue, watch, confirm} = props;
	return(
		<>
			<Box sx={{mt: 5}}>
				<FileSelect 
					id={"personalHistoryPassCode"}
					label={"職務経歴書"}
					watch={watch}
					setValue={setValue}	
					confirm={confirm}
				/>
			</Box>
		</>
	);
} 
