import { FC } from "react";
import { Header } from "components/Header";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { FirstSquare } from "./FirstSquare";
import { SecondSquare } from "./SecondSquare";
import { Work } from "./work/Work";
import { Entry } from "./Entry";
import { grayPalette, purplePalette } from "components/Color";
export const Recruitment: FC = () => {

    const grayTheme = createTheme(grayPalette);
    const purpleTheme = createTheme(purplePalette);
    return (
        <>
                <ThemeProvider theme={purpleTheme}>
                    <Header />
                </ThemeProvider>
                <Box sx={{maxWidth: "1440px",m: "0 auto"}}>
                <ThemeProvider theme={grayTheme}>
                    <section>
                        <FirstSquare />
                        <Box sx={{position: "relative", top: -220, right: 40}}>
                            <Box sx={{textAlign: "end", letterSpacing: "0.1em", lineHeight: "4", fontSize: 20}}>
                                <Box>お客様の利益を最大限追求するためには</Box>
                                <Box>ただ言われたことだけをやっていては務まりません。</Box>
                                <Box>お客様にとって一番の利益は何か</Box>
                                <Box>常に自分で考えて行動する必要があります。</Box>
                                <Box>ですがせっかく自分で考えて行動をしても</Box>
                                <Box>上から押さえつけるような社風では自信を生かせません。</Box>
                                <Box>ローチェでは、社員が声をあげやすくのびのびと働ける会社です</Box>
                                <Box>あなたの成長したい意欲を、全力でサポートしていきます。</Box>
                            </Box>
                        </Box>
                    </section> 
                    <section><SecondSquare /></section>
                    <section><Work /></section>
                    <section><Entry /></section>
                </ThemeProvider>
            </Box>
        </>
    );
}