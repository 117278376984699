import { Box } from "@mui/material";
import { FC } from "react";
import { Prefectures } from "./Prefectures";
import { Address0 } from "./Address0";
import { Address1 } from "./Address1";
import { Address2 } from "./Address2";
import { Zipcode } from "./Zipcode";

export const Address: FC = () => {
	return(
		<>
			<Box sx={main}>
				<Box sx={label}>現住所</Box>
				<Box>
					<Zipcode />
					<Prefectures />
					<Address0 />
					<Address1 />
					<Address2 />
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
}
const label = {
	width: "12%",
	textAlign: "end",
	mr: 10,
}
