import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { dashedLine } from "components/Styles/styles";
import { FC } from "react";
import { GenderType } from "types/entryTypes";

export const Gender: FC<GenderType> = (props) => {
	const {register} = props;
	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={gender}>性別</Box>
				<Box sx={{width:"10%"}}/>
				<RadioGroup aria-label="gender" id="gender" row >
					<FormControlLabel value="男性" control={<Radio  {...register("gender")}/>} label="男性" />
					<FormControlLabel value="女性" control={<Radio  {...register("gender")}/>} label="女性" />
					<FormControlLabel value="その他" control={<Radio  {...register("gender")}/>} label="その他" />
				</RadioGroup>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}
const gender = {
	width: "12%",
	textAlign: "end",
}
