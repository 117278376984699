import { Box, TextField } from "@mui/material";
import { FC } from "react";
import { FormFieldNotRequiredType, FormFieldPatternRequiredType, FormFieldRequiredType, } from "types/entryTypes";


export const FormFieldRequired: FC<FormFieldRequiredType> = (props) => {
	const {id, label, labelWidth, required, register,errorMsg,errors,tel, sx} = props;
	return(
		<>
			<Box sx={{display: "flex", alignItems: "center", ...sx}}>
				<Box component={"span"} sx={{width: labelWidth, mr: 3, textAlign: "end"}}>{label}{tel && <Box component={"span"} sx={{fontSize: 13}}>(ハイフンなし)</Box>}</Box>
				<Box sx={{width: "100%"}}>
					<TextField id={id} required={required} fullWidth
						{...register(id, {
							required: {
								value: true,
								message: errorMsg
							},
						})}
					/>
					{errors.lastName?.message && <Box sx={errorMsgText}>{errors.lastName.message}</Box>}
				</Box>
			</Box>
		</>
	);
} 
export const FormFieldPatternRequired: FC<FormFieldPatternRequiredType> = (props) => {
	const {id, label, labelWidth, required, register,errorMsg,errors, patternValue,patternErrorMsg, sx} = props;
	return(
		<>
			<Box sx={{display: "flex", alignItems: "center", ...sx}}>
				<Box component={"span"} sx={{ width: labelWidth, mr: 3, textAlign: "end"}}>{label}</Box>
				<Box sx={{width: "100%"}}>
					<TextField id={id} required={required} fullWidth
						{...register(id, {
							required: {
								value: true,
								message: errorMsg
							},
							pattern: {
								value: patternValue,
								message: patternErrorMsg,
							}
						})}
					/>
					{errors.lastName?.message && <Box sx={errorMsgText}>{errors.lastName.message}</Box>}
				</Box>
			</Box>
		</>
	);
} 
export const FormFieldNotRequired: FC<FormFieldNotRequiredType> = (props) => {
	const {id, label, labelWidth, register, sx} = props;
	return(
		<>
			<Box sx={{display: "flex", alignItems: "center", ...sx}}>
				<Box component={"span"} sx={{ width: labelWidth, mr: 3, textAlign: "end"}}>{label}</Box>
				<Box sx={{width: "100%"}}>
					<TextField id={id} fullWidth {...register(id)}/>
				</Box>
			</Box>
		</>
	);
} 

const errorMsgText = {
	color: "red",
	mt:1,
	ml:1,
}

