import { Box } from "@mui/material";
import { FC } from "react";
import { FileSelectResult } from "../FileSelectResult";
import { AppDocConfirm, PersonalHistoryType } from "types/entryTypes";

export const PersonalHistory: FC<AppDocConfirm> = (props) => {
	const {watch, setValue} = props;
	return(
		<>
			<Box sx={{mt: 5}}>
				<FileSelectResult label={"職務経歴書"} id={"personalHistoryPassCode"} watch={watch}  setValue={setValue}/>
			</Box>
		</>
	);
} 
