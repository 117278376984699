import { Box, Button, Modal } from "@mui/material";
import { lineHeight } from "@mui/system";
import { textColor, white } from "components/Color";
import { FC, useState } from "react";
import { ModalWindowType } from "types/windowTypes";

export const ModalWindow: FC<ModalWindowType> = (props) => {
    const{open, setOpen, title, content} = props;
   
    const handleClose = () => setOpen(false);

    return(
        <>
            <Modal open={open} onClose={handleClose}>
                <Box sx={main}>
                    <Box sx={titleText}>{title}</Box>
                    <Box sx={contentText}>
                        {content}
                    </Box>
                    <Box sx={buttonContainer}>
                        <Button sx={button} variant={"contained"} onClick={handleClose}>閉じる</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

const main = {
    overflow: "auto",
    width: "50vw",
    height: "80vh",
    m: "80px auto",
    backgroundColor: white,
    color: textColor,
    p: "24px 40px"
}

const titleText = {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    mt: 3,
}

const contentText = {
    mt: 3,
    letterSpacing: "0.1em", 
    lineHeight: "1.5",
}

const buttonContainer = {
    display: "flex",
    justifyContent: "center",
    mt: 5 ,
}
const button = {
    width: 104,
    height: 56,
    fontSize: 18,
}