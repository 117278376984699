import { Box } from "@mui/material";
import { titleGray } from "components/Color";
import { FC } from "react";
import { Address, Birthday, Contact, Gender, Name, NameKana } from "features/entry/components/basicInfomationConfirm";

export const BasicInfomationConfirm: FC = () => {
	return(
		<>
			<Box sx={main}>
				<Box sx={basicInfoText}>基本情報</Box>
				<Name />
				<NameKana />
				<Birthday />
				<Gender />
				<Contact />
				<Address />
			</Box>
		</>
	);
} 

const main = {
	width: "95%",
	m: "0 0 0 auto",
}
const basicInfoText = {
	p:"8px 16px",
	backgroundColor: titleGray,
	fontWeight: "bold",
}