import { Box } from "@mui/material";
import { MandatoryMark } from "components/Mark";
import { dashedLine } from "components/Styles/styles";
import { FC } from "react";
import { ContactType } from "types/entryTypes";
import { Email } from "./Email";
import { Tel } from "./Tel";

export const Contact: FC<ContactType> = (props) => {
	const {register, errors} = props;
	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={contactText}>
					<MandatoryMark />
					ご連絡先
				</Box>
				<Box sx={{width: "80%"}}>
					<Tel register={register} errors={errors} />
					<Email register={register} errors={errors}/>
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}

const contactText = {
	display: "flex", 
    alignItems: "center",
    justifyContent: "end",
	width: "12%",
}