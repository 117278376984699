import { Box } from "@mui/material";
import { orangeShadow } from "components/Color";
import { DeveloperCoding } from "components/Images/jpg";
import { FC } from "react";

export const WebSystemDev: FC = () => {
    const webSystemDevParent = {
        width: "60%",
        position: "relative",
        top: "35%",
        left: "35%",
        zIndex: 40,
    }
    const webSystemDevTextParent = {
        width: "100%",
        position: "relative",
        fontSize: 40,
        fontWeight: "bold",
        left: 30,
    }
    const webSystemDevTextJp = {
        position: "absolute",
        top: -25,
        left: 30,
        zIndex: 20,
    }
    const webSystemDevTextEn = {
        position: "absolute",
        left: 70,
        zIndex: 10,
        color: orangeShadow,
    }
    return(
        <>
            <Box sx={webSystemDevParent}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <DeveloperCoding size={200}/>
                    <Box sx={webSystemDevTextParent}>
                        <Box sx={webSystemDevTextJp}>ウェブシステム開発</Box>                            
                        <Box sx={webSystemDevTextEn}>Web System Development</Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}