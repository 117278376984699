// //中央揃え
// export const flexCustom = (alignItems?: string, justifyContent?: string) => {
//     let flexBox = {
//         "display": "flex", 
//         "alignItems": alignItems,
//         "justifyContent": justifyContent
//     };
//     return {flexBox}
// };

export const dashedLine = () => {
    return {
        backgroundImage: `linear-gradient(to right, #000, #000 2px, transparent 2px, transparent 8px)`,
        backgroundSize: "8px 1px",
        backgroundPosition: "left bottom",
        backgroundRepeat: "repeat-x"    
    }
}