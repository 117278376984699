import { Recruitment, Entry, JobDescription } from "features";

export const publicRoutes = [
    {
        path: "/",
        element: <Recruitment />,
    },
    {
        path: "/jobDescription",
        element: <JobDescription />
    },
    {
        path: "/entry",
        element: <Entry />
    },
];