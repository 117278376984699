import { FC } from "react";
import { ContainedButton } from "components/Buttons/ContainedButton";
import { EntryOrange, EntryShadow } from "components/Images/svg";
import { useFunctions } from "components/Hooks";
import { Box } from "@mui/material";

export const Entry: FC = () => {
    const { screenTransition } = useFunctions();
    const entryParent = {
        width: "100%",
        height: "80vh",
        maxHeight: 800,
        position: "relative",
        top: 100,
        right: 100,
    }
    const entryShadow = {
        position: "absolute",
        top: "75%",
        left: "50%"
    }
    const entryOrange = {
        position: "absolute",
        top: "72%",
        left: "48%"
    }

    const entryButtonParent = {
        position: "absolute",
        top: "68%",
        left: "46%"
    }
    
    const entryButton = {
        width: 400,
        height: 90,
        fontSize: 24,
    }
    return(
        <>
            <Box sx={entryParent}>
                <Box sx={entryShadow}>
                    <EntryShadow size={90} />
                </Box>
                <Box sx={entryOrange}>
                    <EntryOrange size={90} />
                </Box>
                <Box sx={entryButtonParent}>
                    <ContainedButton buttonName="ENTRY" sx={entryButton} onClickFunction={() => screenTransition("/jobDescription") } />
                </Box>
            </Box>
        </>
    );
}