import { Box } from "@mui/material";
import { dashedLine } from "components/Styles/styles";
import { FC,  useState } from "react";
import { BirthDayType } from "types/entryTypes";
import { Day } from "./Day";
import { Month } from "./Month";
import { Year } from "./Year";

export const Birthday: FC<BirthDayType> = (props) => {
	const {register, watch} = props;

	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={birthdayText}>生年月日</Box>
				<Box sx={{width: "10%"}}/>
				<Box sx={selects}>
					<Box sx={year}>
						<Year register={register}/>
					</Box>
					<Box sx={month}>
						<Month register={register} />
					</Box>
					<Box sx={day}>
						<Day register={register} watch={watch} />
					</Box>
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}

const birthdayText = {
	width: "12%",
	textAlign: "end",
}

const selects = {
	display: "flex",
	alignItems: "center",
}

const year = {
	width: 160,
	display: "flex",
	alignItems: "center",
	mr:4,

}
const month = {
	width: 120,
	display: "flex",
	alignItems: "center",
	mr:4,
}
const day = {
	width: 120,
	display: "flex",
	alignItems: "center",
}
