import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getPrefectures } from "modules/api/sonareApi";
import { FC, useEffect, useState } from "react";
import { PrefecturesType } from "types/entryTypes";

export const Prefectures: FC<PrefecturesType> = (props) => {
	const {register, inputRef} = props;
	const [prefectures, setPrefectures] = useState<{prefectureCd: number, prefectureName: string, prefectureNameKana: string}[] | null>(null);
	const initPrefectures = async() => {
		try{
			const prefecturesInfo = await getPrefectures();
			setPrefectures(prefecturesInfo.data.prefectures);
		}	catch(error) {
			console.log(error);
		}
	}
	useEffect(() => {
		initPrefectures();
	},[])

	return(
		<>
			<Box sx={prefecturesComponent}>
				<Box component={"span"} sx={prefectureText}>都道府県</Box>
				<FormControl sx={{width:  120}}>
					<Select native id={"prefectureName"} {...register("prefectureName")} >
						<option value=""></option>
						{prefectures != null && 
							prefectures.map((prefecture) => (
									<option value={prefecture.prefectureName} key={prefecture.prefectureCd}>{prefecture.prefectureName}</option>
							))
						}
					</Select>
				</FormControl>
			</Box>
		</>
	);
} 

const prefecturesComponent = {
	display: "flex",
	alignItems: "center",
	mb: 3,
}

const prefectureText = {
	width: 120,
	textAlign: "end",
	mr: 3,
}