import { Box } from "@mui/material";
import { dashedLine } from "components/Styles/styles";
import { FC, useRef } from "react";
import { AddressType } from "types/entryTypes";
import { Address0 } from "./Address0";
import { Address1 } from "./Address1";
import { Address2 } from "./Address2";
import { Prefectures } from "./Prefectures";
import { Zipcode } from "./Zipcode";

export const Address: FC<AddressType> = (props) => {
	const {register, watch, setValue} = props;
	const inputRef = useRef(null);
	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={addressText}>
					現住所
				</Box>
				<Box sx={{width: "80%"}}>
					<Zipcode register={register} watch={watch} setValue={setValue}  inputRef={inputRef}/>
					<Prefectures register={register} inputRef={inputRef}/>
					<Address0 register={register}/>
					<Address1 register={register}/>
					<Address2 register={register}/>
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
}

const addressText = {
	width: "12%",
	textAlign: "end",
}
