import { FC } from "react";
import { SquareGray, SquareOrange, SquareShadow } from "components/Images/svg";
import { Box } from "@mui/material";
import { squarePropsType } from "types/squareTypes";

type SquareType = {
    parentPosition?: string;
    parentRotate?: string;
    parentLeft?: string;
}

export const Square: FC<SquareType> = (props) => {
    const {parentPosition, parentRotate} = props;
    // styles
    const squareParent = {
        height: "100vh",
        width: "100vw",
        position: `${parentPosition}`,
        left: 200,
        top: 80,
        //回転の基準点
        transformOrigin: "left top", 
        transform: `rotate(${parentRotate}deg)`,
    }
    
    const squareShadow1 = {
        position: "absolute",
        left: 140,
        top: -120,
        zIndex:10,
    }
    const squareShadow2 = {
        position: "absolute",
        left: 40, 
        top: -40,
        zIndex:20,
        
        
    }
    
    const squareOrange = {
        position: "absolute",
        left: 80, 
        top: -80,
        zIndex: 30,
    }

    const squareGray = {
        position: "absolute",
        zIndex: 40,
    }

    return(
        <>
            <Box sx={squareParent}>
                <Box sx={squareShadow1}>
                    <SquareShadow size={600} />
                </Box>
                <Box sx={squareShadow2}>
                    <SquareShadow size={600} />
                </Box>
                <Box sx={squareOrange}>
                    <SquareOrange size={600} />
                </Box>
                <Box sx={squareGray}>
                    <SquareGray size={600} />
                </Box>
            </Box>
        </>
    );
}

export const SquareGrayComponent: FC<squarePropsType> = (props) => {
    const {squareStyle, size} = props;
    return (
        <Box sx={squareStyle}>
            <SquareGray size={size}/>
        </Box>
    );
}

export const SquareOrangeComponent: FC<squarePropsType> = (props) => {
    const {squareStyle, size} = props;
    return (
        <Box sx={squareStyle}>
            <SquareOrange size={size} />
        </Box>
    );
}

export const SquareShadowComponent: FC<squarePropsType> = (props) => {
    const {squareStyle, size} = props;
    return (
        <Box sx={squareStyle}>
            <SquareShadow size={size} />
        </Box>
    );
}