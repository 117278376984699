import { FC } from "react";
import { FormFieldNotRequired } from "features/entry/components/FormFields";
import { Address2Type } from "types/entryTypes";

export const Address2: FC<Address2Type> = (props) => {
	const {register} = props;
	return(
		<>
			<FormFieldNotRequired id={"address2"} label={"建物名"} labelWidth={145} register={register} sx={{ mb: 3 }} />
		</>
	);
} 
