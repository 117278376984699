import { FC } from "react";
import { Box } from "@mui/material";
import { brown, lightGreen, white } from "components/Color";
import { CustomTab } from "components/Tab/CustomTab";
import { MidCareerRecruit } from "features/jobDescription/components";
import { NewGraduateRecruit } from "./newGraduateRecruit/NewGraduateRecruit";

export const JobDescription: FC = () => {
    const tabLabels = ["新卒採用","中途採用"];
    return(
        <>
            <Box sx={{width: "100vw", minHeight: "100vh",backgroundColor: lightGreen}}>
                <Box sx={main}>
                    <Box sx={jobDescription}>募集要項</Box>
                    <Box sx={tabContainer}>
                        <CustomTab labels={tabLabels} component1={<NewGraduateRecruit />} component2={<MidCareerRecruit />}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
}


const main = {
    width: "80%", 
    maxWidth: "1440px",
    minHeight: "100vh",
    mr: "auto",
    ml: "auto",
    pr: "48px",
    pl: "48px",
    pb: 10,
    backgroundColor: white, 
}

const jobDescription = {
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: brown,
    height: 160,
    fontSize: 48,
    color: white,
    fontWeight: "bold",
}

const tabContainer = {
    width: "85%",
    m: "80px auto 0 auto",
    border: "solid 1px black",
    borderRadius: "10px",
}