import { AxiosRequestHeaders } from "axios";
import { sonareAxios } from "lib/axios";
import { getSonareHeaders } from "modules/api/headers";
import { UseFormValuesType } from "types/validationType";

//企業側にメール送信
export const entryForCorporate = async (body: {[key: string]:UseFormValuesType[]}) => {
    const headers = getSonareHeaders();
    const result = await sonareAxios.post(`/mailbox/recruit/entry_for_corporate`, body, {headers: headers as AxiosRequestHeaders});
    return result;
    
}

//求職者側に送信
export const entryForApplicant = async(body:{[key: string]:UseFormValuesType[]}) => {
    const headers = getSonareHeaders();
    const result = await sonareAxios.post(`/mailbox/recruit/entry_for_applicant`, body, {headers: headers as AxiosRequestHeaders});
    return result;
}