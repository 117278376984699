import { Box } from "@mui/material";
import { FormDataContext } from "providers/entry";
import { FC, useContext } from "react";

export const Email: FC = () => {
	const {formData} = useContext(FormDataContext);
	return(
		<>
			<Box sx={main}>
				<Box sx={{ml:"36px", mr: 3}}>メールアドレス</Box>
				<Box>{formData?.email}</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	mt: 1,
}


