import { FC } from "react";
import { SquareGrayComponent, SquareOrangeComponent, SquareShadowComponent } from "components/Square";
import { white } from "components/Color";
import { Box } from "@mui/material";

export const SecondSquare: FC = () => {
    const squareParent = {
        position: "relative",
        height: "100vh",
        maxHeight: 880,
        top: 30,
        width: "100%"
    }
    const squareShadow1 = {
        position: "absolute",
        left: "30%",
        transform: `rotate(45deg)`,
        zIndex: 10,

    }

    const squareShadow2 = {
        position: "absolute",
        left: "28%",
        top: 30,
        zIndex: 20,
    }

    const squareOrange = {
        position: "absolute",
        left: "25%",
        transform: `rotate(45deg)`,
        zIndex: 30,
        
    }

    const squareGray = {
        position: "absolute",
        left: "25%",
        zIndex: 40
    }

    const squareTextParent = {
        position: "absolute",
        left: "31%",
        zIndex: 50
    }
    return (
        <>
            <Box sx={squareParent}>
                <SquareShadowComponent size={700} squareStyle={squareShadow1} />
                <SquareShadowComponent size={700} squareStyle={squareShadow2} />
                <SquareOrangeComponent size={700} squareStyle={squareOrange} />
                <SquareGrayComponent size={700} squareStyle={squareGray} />
                <Box sx={squareTextParent}>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                        <Box sx={{fontSize: 48, fontWeight: 500, color: white}}><h1>MISSION</h1></Box>
                        <Box sx={{fontSize: 24, fontWeight: "bold", color: white, lineHeight: 1.5}}><h2>ITのチカラで、少しでもお客様に<br/>『ラク』をさせたい。</h2></Box>
                        <Box sx={{color: white, lineHeight: "1.8"}}>私たちは、日々仕事をしていく中でたくさんの『手順』を踏んで<br/>処理をしていきます。<br/>
                            まずあれをして、次にこれをして<Box component={"span"} sx={{fontSize: 10}}>•••</Box>作業に慣れてきてふと思うこ<br/>とは「これ、いっぺんにできたらいいのにな」<br/>
                            ローチェでは、そんなお客様の「面倒くさい」を最大限なくして<br/>いくのがモットーです。<br/>
                            「お客様が『ラク』をできるのなら、どんな苦労だってできる」<br/>それが、代表の想いです。
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}