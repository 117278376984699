import { createTheme, ThemeProvider } from "@mui/material";
import { grayButtonPalette, orangePalette } from "components/Color";
import { createContext, useState } from "react";
import { UseFormValuesType } from "types/validationType";


type ProviderProps = {
    children: React.ReactNode;
}

export const FormDataContext = createContext({} as {formData: UseFormValuesType | null, setFormData: React.Dispatch<React.SetStateAction<UseFormValuesType | null>>});
export const FormDataProvider = ({children}: ProviderProps) => {
    const [formData, setFormData] = useState<UseFormValuesType | null>(null);
    return(
        <>
            <FormDataContext.Provider value={{formData,setFormData}}>
                {children}
            </FormDataContext.Provider>
            
        </>
    );
}

export const OrangeButtonProvider = ({children}: ProviderProps) => {
    const orangeTheme = createTheme(orangePalette);
    return(
        <>
            <ThemeProvider theme={orangeTheme}>
                {children}
            </ThemeProvider>
        </>
    );
}

export const GrayButtonProvider = ({children}: ProviderProps) => {
    const grayTheme = createTheme(grayButtonPalette);
    return(
        <>
            <ThemeProvider theme={grayTheme}>
                {children}
            </ThemeProvider>
        </>
    );
}