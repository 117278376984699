import { Box } from "@mui/material";
import { FormDataContext } from "providers/entry";
import { FC, useContext } from "react";

export const Address1: FC = () => {
	const {formData} = useContext(FormDataContext);
	return(
		<>
			<Box sx={main}>
				<Box sx={{mr: 3}}>　　番地</Box>
				<Box>{formData?.address1}</Box>
			</Box>
		</>
	);
} 
const main = {
	display: "flex",
	alignItems: "center",
	mt: 2,
}

