import { FC } from "react";
import { ContainedButton } from "components/Buttons/ContainedButton";
import { ReadMoreIcon } from "components/Images/icon";
import { WorkOrange, WorkOrangeShadow } from "components/Images/svg";
import { SystemDev } from "./SystemDev";
import { WebSystemDev } from "./WebSystemDev";
import { WorkTitle } from "./WorkTitle";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export const Work: FC = () => {
    const onClickReadMore = () => {
        ""
    }
    const workParent = {
        width: "100%",
        height: "100vh",
        maxHeight: 800,
        position: "relative",
        top: 250,
        right: 250,
    }
    const workOrangeShadow = {
        position: "absolute",
        top: 50,
        left: "35%",
        zIndex:10,
    }
    const workOrange = {
        position: "absolute",
        left: "30%",
        zIndex:20,
    }
   
   
    

    return(
        <>
            <Box sx={workParent}>

                <Box sx={workOrangeShadow}>
                    <WorkOrangeShadow size={900} />
                </Box>
                <Box sx={workOrange}>
                    <WorkOrange size={900} />
                </Box>
                <WorkTitle />
                <SystemDev />
                <WebSystemDev />
                <Box sx={{position: "absolute", top: "95%", left: "60%", zIndex: 40}}>
                    <Button variant="contained" component={Link} to={"https://www.roce.co.jp"} sx={{p:"16px 40px 16px 56px", fontSize: 18}} endIcon={<ReadMoreIcon size={10} />}>READ MORE　</Button>
                </Box>
            </Box>
        </>
    );
}