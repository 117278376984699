import { Box } from "@mui/material";
import { FormDataContext } from "providers/entry";
import { FC, useContext } from "react";

export const Tel: FC = () => {
	const {formData} = useContext(FormDataContext);
	return(
		<>
		
			<Box sx={main}>
				<Box sx={{ml:10, mr: 3}}>電話番号</Box>
				<Box>{formData?.tel}</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
}
