import { emailErrorMsg, emailValidationMessage, emailValidationPattern, telErrorsMsg } from "components/Common/validation";
import { FC } from "react";
import { EmailType } from "types/entryTypes";
import { FormFieldPatternRequired } from "features/entry/components/FormFields";

export const Email: FC<EmailType> = (props) => {
	const {register, errors} = props;
	
	return(
		<>
			<FormFieldPatternRequired id={"email"} label={"メールアドレス"} labelWidth={200} required={true} register={register} errors={errors} errorMsg={emailErrorMsg} sx={{ mb: 3 }} patternValue={emailValidationPattern} patternErrorMsg={emailValidationMessage}/>
			<FormFieldPatternRequired id={"emailConfirm"} label={"確認用"} labelWidth={200} required={true} register={register} errors={errors} errorMsg={emailErrorMsg} patternValue={emailValidationPattern} patternErrorMsg={emailValidationMessage}/>
		</>
	);
} 
