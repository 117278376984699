import { Box } from "@mui/material";
import { white } from "components/Color";
import { FC } from "react";

export const End: FC = () => {
    return(
        <>
            <Box sx={{ml: 5, height: "100vh", lineHeight: 1.6}}>
                <Box sx={{fontSize: 24, m: "32px 0"}}>応募が完了しました。</Box>
                <Box>ご応募ありがとうございます。</Box>
                <Box>内容をご確認後、採用担当よりご連絡させて頂きます。</Box>
                <Box>ご入力いただいたメールアドレス宛に応募受付メールをお送りしましたので、ご確認くださいませ。</Box>
                
                <Box sx={{mt: 4}}>メールが届かない場合は、受付メールが迷惑フォルダ等に振り分けられている可能性がございます。</Box>
                <Box>お手数ですが弊社のドメインを受信許可していただくか、迷惑メールフォルダ等をご確認ください。</Box>
                
                <Box sx={{mt: 4}}>上記の対応でも届いていなかった場合は、お手数ですがもう一度応募フォームよりご応募いただくか、</Box>
                <Box>弊社へのお問い合わせをお願い致します。</Box>
            </Box>
        </>
    );
}
