import { FC } from "react";
import {Button} from "@mui/material";
import {ButtonType} from "types/buttonTypes";

export const ContainedButton: FC<ButtonType> = (props) => {
    const {buttonName,startIcon, endIcon, sx, onClickFunction, disabled} = props;
    return (
        disabled ? 
            <Button variant="contained" onClick={onClickFunction} startIcon={startIcon} endIcon={endIcon} sx={sx} disabled>{buttonName}</Button>
        :
            <Button variant="contained" onClick={onClickFunction} startIcon={startIcon} endIcon={endIcon} sx={sx} >{buttonName}</Button>
        
    );
}