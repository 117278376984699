import { FC } from "react";
import { ImgTemplate } from "components/Images/ImgTemplate";
import { Size } from "types/imageTypes";
import {entryOrange, entryShadow, squareGray, squareOrange, squareShadow, workOrange, workOrangeShadow, workTitleBg, workTitleBgShadow} from "assets";

export const SquareGray: FC<Size> = (props) => {return <ImgTemplate imageFilePath={squareGray} imageExplanation={"squareGray"} size={props.size} />};
export const SquareOrange: FC<Size> = (props) => {return <ImgTemplate imageFilePath={squareOrange} imageExplanation={"squareGray"} size={props.size} />};
export const SquareShadow: FC<Size> = (props) => {return <ImgTemplate imageFilePath={squareShadow} imageExplanation={"squareGray"} size={props.size} />};
export const WorkOrange: FC<Size> = (props) => {return <ImgTemplate imageFilePath={workOrange} imageExplanation={"squareGray"} size={props.size} />};
export const WorkOrangeShadow: FC<Size> = (props) => {return <ImgTemplate imageFilePath={workOrangeShadow} imageExplanation={"workOrangeShadow"} size={props.size} />};
export const WorkTitleBg: FC<Size> = (props) => {return <ImgTemplate imageFilePath={workTitleBg} imageExplanation={"workTitleBg"} size={props.size} />};
export const WorkTitleBgShadow: FC<Size> = (props) => {return <ImgTemplate imageFilePath={workTitleBgShadow} imageExplanation={"workTitleBgShadow"} size={props.size} />};
export const EntryShadow: FC<Size> = (props) => {return <ImgTemplate imageFilePath={entryShadow} imageExplanation={"entryShadow"} size={props.size} />};
export const EntryOrange: FC<Size> = (props) => {return <ImgTemplate imageFilePath={entryOrange} imageExplanation={"entryOrange"} size={props.size} />};
