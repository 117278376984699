import { FC } from "react";
import { Box } from "@mui/material";
import { FormFieldRequired } from "features/entry/components/FormFields";
import { dashedLine } from "components/Styles/styles";
import { NameKanaType } from "types/entryTypes";
import { firstNameErrorMsg, lastNameErrorMsg } from "components/Common/validation";
import { MandatoryMark } from "components/Mark";

export const NameKana: FC<NameKanaType> = (props) => {
	const {register, errors} = props;
	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={nameKana}>
					<MandatoryMark />
					氏名 (カナ)
				</Box>
				<Box sx={fields}>
					<FormFieldRequired id={"lastNameKana"} label={"セイ"} labelWidth={40} required={true} sx={{width:"40%"}} register={register} errors={errors} errorMsg={lastNameErrorMsg}/>
					<FormFieldRequired id={"firstNameKana"} label={"メイ"} labelWidth={40} required={true} sx={{width:"40%"}} register={register} errors={errors} errorMsg={firstNameErrorMsg}/>
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}
const nameKana = {
	display: "flex", 
    alignItems: "center",
    justifyContent: "end",
	width: "12%",
}
const fields = {
	width: "85%",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-around",
}

