import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material"
import { TabPanelType, TabType } from "types/tabTypes";
import { OrangeButtonProvider } from "providers/entry";
import { grayLine, lightGreen } from "components/Color";
import { styled } from "@mui/system";

export const CustomTab: FC<TabType> = (props) => {
    const {labels, component1, component2, tabPanelSx} = props;

    const [value, setValue] = useState(1);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return ( 
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider", "& .css-1eocevo-MuiTabs-indicator": {backgroundColor: lightGreen}}}>
                <OrangeButtonProvider>
                    <Tabs value={value} onChange={handleChange}>
                        {labels.map((label, index) => (
                            <Tab key={`${label}-${index}`} label={label} {...a11yProps(index)} sx={{fontSize: 24,fontWeight:"bold",m:"16px 0", width: "50%", maxWidth: "none"}} />
                        ))}
                    </Tabs>
                </OrangeButtonProvider>
            </Box>
            <Box sx={tabPanelSx}>
                <TabPanel value={value} index={0}>
                    {component1}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {component2}
                </TabPanel>
            </Box>
            
        </>
    )
}

export const TabPanel: FC<TabPanelType> = (props) => {
    const {value, index, children} = props;
    return(
        <>
            {value === index && (
                <Box>{children}</Box>
            )}
        </>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}
