export const textColor = "#707070";
export const grayLine = "#707070";

export const white = "#ffffff";
export const orange = "#e25f00";
export const orangeShadow = "#f1b386";
//黄緑
export const lightGreen = "#e0f4c5";
//茶色
export const brown = "#493528";
export const titleGray = "#cccccc";
export const titleOrange = "#ff9243";

export const gray = "#464646";
export const darkGray = "#252525";
export const lightGray = "#656565";

export const purple = "#4700B9";
export const darkPurple = "#2C00B0";
export const lightPurple = "#561DC1";

