import { createTheme, ThemeProvider } from "@mui/material";
import { grayPalette } from "components/Color";
import { ProviderPropsType } from "types";

export const GrayThemeProvider = ({children}: ProviderPropsType) => {
    const grayTheme = createTheme(grayPalette);
    return(
        <>
            <ThemeProvider theme={grayTheme}>
                {children}
            </ThemeProvider>
        </>
    );
}
