import { Box } from "@mui/material";
import { dashedLine } from "components/Styles/styles";
import { FC } from "react";
import { Email } from "./Email";
import { Tel } from "./Tel";

export const Contact: FC = () => {
	return(
		<>
			<Box sx={main}>
				<Box sx={label}>ご連絡先</Box>
				<Box>
					<Tel />
					<Email />
				</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}
const label = {
	width: "12%",
	textAlign: "end",
}