import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { GrayThemeProvider } from "providers/colorTheme";
import { FC, useEffect, useState } from "react";
import { DialogWindowType } from "types/windowTypes";

export const DialogWindow: FC<DialogWindowType> = (props) => {
    const {title,open, buttonType,  onClickAccept, onClose, sx} = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);
    return(
        <>
            <GrayThemeProvider>
                <Dialog open={dialogOpen} onClose={onClose}>
                    <DialogTitle sx={sx}>{title}</DialogTitle>
                <DialogActions>
                    {buttonType === "OkOnly"  && 
                        <Button variant="text" onClick={onClickAccept}>閉じる</Button>
                    }
                    {buttonType === "YesNo" && 
                        <>
                            <Button variant="text" onClick={onClickAccept}>はい</Button>
                            <Button variant="text" onClick={onClose}>いいえ</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            </GrayThemeProvider>
        </>
    );
}