
export const lastNameErrorMsg: string = " ＊入力必須項目です";
export const firstNameErrorMsg: string = "＊入力必須項目です";
export const lastNameKanaErrorMsg: string = "＊入力必須項目です";
export const firstNameKanaErrorMsg: string = "＊入力必須項目です";
export const telErrorsMsg: string =  "＊入力必須項目です";
export const emailErrorMsg: string = "＊入力必須項目です";

//メールアドレス
export const emailValidationPattern: RegExp = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
export const emailValidationMessage: string = "＊メールアドレスを入力してください"