import { FC } from "react";
import { FormFieldNotRequired } from "features/entry/components/FormFields";
import { Address0Type } from "types/entryTypes";

export const Address0: FC<Address0Type> = (props) => {
	const {register} = props;
	return(
		<>
			<FormFieldNotRequired id={"address0"} label={"市区町村"} labelWidth={145} register={register} sx={{ mb: 3 }} />
		</>
	);
} 
