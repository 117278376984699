import { Box } from "@mui/material";
import { orangeShadow } from "components/Color";
import { CodingOnLaptop } from "components/Images/jpg";
import { FC } from "react";

export const SystemDev: FC = () => {
    const systemDevParent = {
        width: "60%",
        position: "relative",
        top: "30%",
        left: "35%",
        zIndex: 40,
    }
    const systemDevTextParent = {
        width: "100%",
        position: "relative",
        fontSize: 40,
        fontWeight: "bold",
    }
    const systemDevTextJp = {
        position: "absolute",
        top: -25,
        zIndex: 20,
    }
    const systemDevTextEn = {
        position: "absolute",
        left: 40,
        zIndex: 10,
        color: orangeShadow,
    }
    return(
        <>
            <Box sx={systemDevParent}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Box sx={systemDevTextParent}>
                        <Box sx={systemDevTextJp}>システム開発</Box>                      
                        <Box sx={systemDevTextEn}>System Development</Box>
                    </Box>
                    <CodingOnLaptop size={200}/>
                </Box>
            </Box>
        </>
    );
}