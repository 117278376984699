import { SONARE_PUB_CLIENT_ID, SONARE_PUB_CLIENT_SECRET, SONARE_CLIENT_ID, SONARE_CLIENT_SECRET } from "config";
import { HeadersType } from "types/common";

export const getSonareHeaders = () => {
    const headers :HeadersType = {};
    headers['x-client-id'] = SONARE_CLIENT_ID;
    headers['x-client-secret'] = SONARE_CLIENT_SECRET;
    return headers
}

export const getSonarePubHeaders = () => {
    const headers: HeadersType = {};
    headers['x-client-id'] = SONARE_PUB_CLIENT_ID;
    headers['x-client-secret'] = SONARE_PUB_CLIENT_SECRET;
    return headers
}