import { FC, useEffect, useState } from "react";
import {useDropzone} from "react-dropzone";
import { FileSelectType } from "types/entryTypes";
import { Box, Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFile,  getUploadFileInfo, uploadFile } from "modules/api/sonareApi";
import { getRegisterPassCode } from "modules/api/roceApi";

export const FileSelect: FC<FileSelectType> = (props) => {
	const {label, setValue, watch, id, confirm} = props;
	let {acceptedFiles,getRootProps,open} = useDropzone({noClick: true});	
	const [fileName, setFileName] = useState<string | null>(null);


	const upload = async () => {
		try {
			if(acceptedFiles[0] && setValue != undefined) {
				//passCode取得
				const fileAccessInfo = await getRegisterPassCode();
				//登録するbody
				const body = new FormData();
				body.append("file", acceptedFiles[0]);
				//ファイルをアップロード(まだ永続化されていない)
				const uploadFileResult = await uploadFile(fileAccessInfo.data.passCode, body);
				setFileName(uploadFileResult.data.fileName);
				setValue(id, fileAccessInfo.data.passCode);
			}
			console.log(acceptedFiles);
		}catch(error) {
			console.log(error);
		}
	}

	const getFileInfo = async () => {
		try {
			if(watch != undefined) {
				const filePassCode = watch(id) as string;
				if(filePassCode !== "" && filePassCode !== undefined) {
					const uploadFileInfo = await getUploadFileInfo(filePassCode);
					setFileName(uploadFileInfo.data.fileName);
				}
			}
		}catch(error) {
			console.log(error);
		}
	}

	const onClickFileRemove = async () => {
		try {
			if(watch != undefined && setValue !== undefined) {
				const passCode = watch(id) as string;
				if(passCode !== "" && passCode !== undefined) {
					await deleteFile(passCode);
					setFileName(null);
					setValue(id, "");
				}
			}
		}catch(error) {
			console.log(error)
		}
	}

	useEffect(()=> {
		upload();
	},[acceptedFiles])

	useEffect(() => {
		getFileInfo();
	},[confirm === false]);

	return(
		<>
			<Box {...getRootProps()} sx={fileSelectContainer}>
				<Box component={"span"} sx={{mr: 3}}>{label}</Box>
				<Button variant="contained" onClick={open} sx={{mr: 3}}>ファイルを選択</Button>
				{fileName != null &&
					<Box component={"span"}>{fileName}</Box>
				}
				<IconButton onClick={onClickFileRemove}> <DeleteIcon /></IconButton>
			</Box>
		</>
	);
} 

const fileSelectContainer = {
	display: "flex",
	alignItems: "center",
}
