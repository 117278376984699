import { Box } from "@mui/material";
import { dashedLine } from "components/Styles/styles";
import { FormDataContext } from "providers/entry";
import { FC, useContext } from "react";

export const Name: FC = () => {
	const {formData} = useContext(FormDataContext);
	return(
		<>
			<Box sx={main}>
				<Box sx={label}>氏名</Box>
				<Box sx={firstName}>{formData?.lastName}</Box>
				<Box>{formData?.firstName}</Box>
			</Box>
		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "40px 0 24px 0",
	pb: 3,
	...dashedLine(),
}
const label = {
	width: "12%",
	textAlign: "end",
	mr: 10,
}
const firstName = {
	mr: 3,
}