import { Box } from "@mui/material";
import { FC } from "react";
import { FormFieldRequired } from "features/entry/components/FormFields";
import { dashedLine } from "components/Styles/styles";
import { NameType } from "types/entryTypes";
import { firstNameErrorMsg, lastNameErrorMsg } from "components/Common/validation";
import { MandatoryMark } from "components/Mark";

export const Name: FC<NameType> = (props) => {
	const {register, errors} = props;
	return(
		<>
			<Box sx={main}>
				<Box component={"span"} sx={nameText}>
					<MandatoryMark />
					氏名
				</Box>
				<Box sx={fields}>
					<FormFieldRequired id={"lastName"} label={"姓"} labelWidth={40}required={true} register={register} errors={errors} errorMsg={lastNameErrorMsg} sx={{width: "40%"}}/>
					<FormFieldRequired id={"firstName"} label={"名"} required={true}labelWidth={40} register={register} errors={errors} errorMsg={firstNameErrorMsg} sx={{width: "40%"}}/>
				</Box>
			</Box>

		</>
	);
} 

const main = {
	display: "flex",
	alignItems: "center",
	m: "24px 0",
	pb: 3,
	...dashedLine(),
}
const nameText = {
	display: "flex", 
    alignItems: "center",
    justifyContent: "end",
	width: "12%",
}
const fields = {
	width: "85%",
	display: "flex", 
    alignItems: "center",
    justifyContent: "space-around",
}

const errorMsgText = {
	color: "red",
	ml: 8
}
