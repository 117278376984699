import { Box } from "@mui/material";
import { white } from "components/Color";
import { WorkTitleBg, WorkTitleBgShadow } from "components/Images/svg";
import { FC } from "react";

export const WorkTitle: FC = () => {
    const workTitleParent = {
        position: "relative",
        
    }
    const workTitleBgShadow = {
        position: "absolute",
        top: 150,
        left: "24%",
    }
    const workTitleBg = {
        position: "absolute",
        top: 80,
        left: "23%",
        zIndex: 30,
    }
    const work = {
        position: "absolute",
        top: 125,
        left: "30%",
        color: white,
        fontSize: 56,
        fontWeight: "bold",
        zIndex: 40,
    }
    return(
        <>
            <Box sx={workTitleParent}>
                <Box sx={workTitleBgShadow}>
                    <WorkTitleBgShadow  size={150}/>
                </Box>
                <Box sx={workTitleBg}>
                    <WorkTitleBg size={150}/>
                </Box>
                <Box sx={work}>
                    WORK
                </Box>
            </Box>    
        
        </>
    );
}