import { FC } from "react";
import { FormFieldNotRequired } from "features/entry/components/FormFields";
import { Address1Type } from "types/entryTypes";

export const Address1: FC<Address1Type> = (props) => {
	const {register} = props;
	return(
		<>
			<FormFieldNotRequired id={"address1"} label={"番地"} labelWidth={145} register={register} sx={{ mb: 3 }} />
		</>
	);
} 
