import { TextField, Box, Button } from "@mui/material";
import { getAddress } from "modules/api/sonareApi";
import { FC } from "react";
import { ZipcodeType } from "types/entryTypes";

export const Zipcode: FC<ZipcodeType> = (props) => {
	const {register, watch, setValue, inputRef} = props

	const onClickSearch = async () => {
		try {
			const leftZipcode = watch("leftZipcode") as string;
			const rightZipcode = watch("rightZipcode") as string;
			//サーバー停止中のため一旦使用できない使用でき次第検索結果を他のフィールドに埋め込み
			const addressInfo = await getAddress(`${leftZipcode}${rightZipcode}`);
			setValue("prefectureName", addressInfo.data.prefectureName);
			setValue("address0", addressInfo.data.localGovernmentName);
			if(addressInfo.data.towns.length === 1) {
				setValue("address0", `${addressInfo.data.localGovernmentName}${addressInfo.data.towns[0]}`);
			}
			const element: any = document.getElementById("prefectureName");
			element.options[addressInfo.data.prefectureCd].selected = true;
		}catch(error) {
			console.log(error);
		}
	}
	return(
		<>
			<Box sx={zipcodeContainer}>
				<Box component={"span"} sx={{width: 120, mr: 3, textAlign: "end"}}>郵便番号</Box>
				<Box component={"span"} sx={{mr: 2, textAlign: "end"}}>〒</Box>
				<TextField id={"leftZipcode"} label={"3桁"} sx={{width: 80}} inputProps={{inputMode: 'numeric', maxLength: 3, pattern: "^[0-9]+$"}} {...register("leftZipcode")} />
				<Box sx={{m: "0 16px"}}>ー</Box>
				<TextField id={"rightZipcode"} label={"4桁"} sx={{width: 104, mr: 4}} inputProps={{inputMode: 'numeric', maxLength: 4, pattern: "^[0-9]+$"}} {...register("rightZipcode")} />
				<Button variant={"contained"} onClick={onClickSearch}>郵便番号から住所検索</Button>
			</Box>
		</>
	);
} 

const zipcodeContainer = { 
	display: "flex",
	alignItems: "center",
	mb: 3,
}
