import { FC, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { UseFormValuesType } from "types/validationType";
import { ApplicationDocument } from "./applicationDocument/ApplicationDocument";
import { BasicInfomation } from "./basicInfomation/BasicInfomation";
import { Box,} from "@mui/material";
import { brown,  lightGreen, titleOrange, white } from "components/Color";
import { EntryButton } from "./EntryButton";
import { PersonalInfomation } from "./PersonalInfomation";
import { FormDataProvider, GrayButtonProvider, OrangeButtonProvider } from "providers/entry";
import { BasicInfomationConfirm } from "./basicInfomationConfirm/BasicInfomationConfirm";
import { ApplicationDocumentConfirm } from "./applicationDocumentConfirm/ApplicationDocumentConfirm";
import { End } from "./end";

export const Entry: FC = () => {
    const {register, handleSubmit, formState:{errors}, watch, setValue} = useForm<UseFormValuesType>();
    const [checked, setChecked] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [entryEnd, setEntryEnd] = useState<boolean>(false);
    return (
        <>
            <FormDataProvider>
                <Box sx={{width: "100vw", backgroundColor: lightGreen}}>
                    {!entryEnd ? 
                        <Box sx={main}>
                            <GrayButtonProvider>
                                <Box sx={entry}>Entry</Box>
                                <Box sx={entryForm}>応募フォーム</Box>
                                {!confirm ? 
                                    <>
                                        <Box sx={infoText}>ページの案内に従い、入力してください。</Box>
                                        <BasicInfomation register={register} errors={errors} watch={watch} setValue={setValue}/>
                                        <ApplicationDocument register={register} setValue={setValue} watch={watch} confirm={confirm}/>
                                    </>
                                    :
                                    <>
                                        <Box sx={infoText}>内容をご確認の上、『応募する』ボタンを押して下さい。</Box>
                                        <BasicInfomationConfirm />
                                        <ApplicationDocumentConfirm watch={watch} setValue={setValue}/>
                                    </>
                                }
                            </GrayButtonProvider>
                            {!confirm &&  <PersonalInfomation  checked={checked} setChecked={setChecked}/>}
                            <OrangeButtonProvider>
                                <EntryButton checked={checked} confirm={confirm} setConfirm={setConfirm} handleSubmit={handleSubmit} setEntryEnd={setEntryEnd} />
                            </OrangeButtonProvider>
                        </Box>
                    : 
                        <Box sx={main}>
                            <Box sx={entry}>Entry</Box>
                            <Box sx={entryForm}>応募完了</Box>
                            <End />
                        </Box>
                    }
                </Box>
            </FormDataProvider>
        </>
    );
}

const main = {
    width: "80%",
    maxWidth: "1440px",
    m: "0 auto",
    p: "0 48px",
    backgroundColor: white, 
}
const entry = {
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: brown,
    height: 160,
    fontSize: 48,
    color: white,
    fontWeight: "bold",
}

const entryForm = {
    mt: 8,
    ml: 3,
    p: 2,
    backgroundColor: titleOrange,
    color: white,
    fontWeight: "600",
    fontSize: 18,
}

const infoText  = {
    m: "16px 40px",
}