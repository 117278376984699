import { Box } from "@mui/material";
import { FormDataContext } from "providers/entry";
import { FC, useContext } from "react";

export const Message: FC = () => {
    const {formData} = useContext(FormDataContext);
    return(
        <>
            <Box sx={messageContainer}>
                <Box sx={{mr: 10}}>
                    <Box>担当採用者への</Box>
                    <Box sx={{textAlign: "end"}}>メッセージ</Box>
                </Box>
                <Box>
                    {formData?.recuruitmentOfiicer}
                </Box>
            </Box>
        </>
    );
}

const messageContainer = {
    ml: 3,
    mt: 5,
    mb: "122px",
    display: "flex",
}