import { FC, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, createTheme } from "@mui/material";
import { MinusIcon, PlusIcon } from "components/Images/icon";
import { grayLine, textColor, titleOrange } from "components/Color";
import { SingleLine } from "components/Lines/Lines";
import { AccordionDetailsFormat } from "../AccordionDetailsFormat";
import { OrangeButtonProvider } from "providers/entry";
import { useFunctions } from "components/Hooks";

export const MidCareerRecruit: FC = () => {
    const {screenTransition} = useFunctions();
    const [plusMinus, setPlusMinus] = useState<boolean>(true);


    const onClickAccordion = () => {
        setPlusMinus(!plusMinus);
    }


    return (
        <>
            <Box sx={main}>
                <Box sx={occupation}>募集職種</Box>
                <Box sx={{mb:3}}>
                    <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                </Box>
                <Accordion sx={{boxShadow: "none"}}>
                {plusMinus ?
                    <>
                        <AccordionSummary expandIcon={<MinusIcon size={32} />} onClick={onClickAccordion}>
                            <Box sx={programmerText}>プログラマー</Box>
                        </AccordionSummary><AccordionDetails>
                        <AccordionDetailsFormat label={"仕事内容"}>業務アプリケーションを主とした情報システムの製造</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={<><Box>歓迎する</Box><Box sx={{ mr: "19px" }}>スキル</Box></>}>
                            <Box>JAVAやC＃など、プログラミング言語を習得した方</Box>
                            <Box>情報処理資格やベンダー資格を取得した方</Box>
                            <Box>現在、IT関係の学校でプログラム制作の勉強をしている方</Box>
                            <Box>プログラムを書くことに熱い情熱を持っている方</Box>
                        </AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"雇用形態"}>正社員 (試用期間あり、その他労働条件変更無)</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"給与"}>
                            <Box>固定給制 月給21万円以上</Box>
                            <Box sx={{ mt: 3, }}>※上記の金額には、およそ20時間(１日あたり１時間)のみなし残業代が含まれます。</Box>
                            <Box>それ以上の残業が発生した場合、別途残業代を支給します。</Box>
                            <Box>※年齢・経験・能力を考慮のうえ、優遇いたします。</Box>
                            <Box>※時間外手当、通勤手当別途支給</Box>
                        </AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"昇給"}>年1回（4月）</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"賞与"}>年2回（6月、12月）</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"諸手当"}>通勤手当(月40000円まで)、役職手当、職能手当、資格取得報奨金</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"勤務地"}>本社または顧客先（都内および首都圏）</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"勤務時間"}>9:00〜18:00（業務内容により変更の場合あり）</AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"休日・休暇"}>
                            <Box>週休2日（土日祝）、夏季休暇、年末年始</Box>
                            <Box>年次有給休暇（初年度10日）、慶弔休暇</Box>
                        </AccordionDetailsFormat>
                        <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                        <AccordionDetailsFormat label={"福利厚生"}>各種社会保険完備（雇用・労災・健康・厚生年金）誕生月食事補助有り</AccordionDetailsFormat>
                            <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                            <OrangeButtonProvider>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button variant={"contained"} sx={entryButton} onClick={() => screenTransition("/entry")}>Entry</Button>
                                </Box>
                            </OrangeButtonProvider>
                        </AccordionDetails>
                    </>
                :
                    <AccordionSummary  expandIcon={<PlusIcon size={32}/>} onClick={onClickAccordion}>
                        <Box sx={{fontSize: 24, fontWeight:"bold", color: textColor}}>プログラマー</Box>
                    </AccordionSummary>
                }
                </Accordion>
            </Box>
        </>
    );
}

const main = {
    ml: 10,
    mr: 10,
    mb: 4,
    overflow: "auto",
}

const  occupation = {
    mt: 3,
    mb: 3,
    fontSize: 24,
    fontWeight: "bold",
    color: textColor,
}

const programmerText = {
    color: titleOrange,
    fontSize: 24,
    fontWeight:"bold",
}

const entryButton = {
    width: 240,
    height: 88,
    fontSize: 32,
    fontWeight: "bold",
    mt: 5,
    mb: 2,
}
