import { FC, useState } from "react";
import { FileSelect } from "features/entry/components/applicationDocument/FileSelect";
import { Box } from "@mui/material";
import { JobCareerType } from "types/entryTypes";

export const JobCareer: FC<JobCareerType> = (props) => {
	const {setValue, watch, confirm} = props;
	return(
		<>
			<Box sx={{mt: 7}}>
				<FileSelect 
					id={"jobCareerPassCode"}
					label={"　　履歴書"} 
					watch={watch}
					setValue={setValue}	
					confirm={confirm}
				/>
			</Box>
		</>
	);
} 
