import { useNavigate } from "react-router-dom";
import { ObjectType } from "types";



export const useFunctions = () => {
    const navigate = useNavigate();
    //画面遷移メソッド
    const screenTransition = (path: string, state?: ObjectType) => {
        navigate(`${path}`,{state: state});
    }

    //useStateの配列初期化メソッド
    const initStateArray = (setState: any) => {
    setState((prevArray: any[]) => [...prevArray].filter((index) => {
        return index === null;
    }));
}
    return {screenTransition, initStateArray};
}