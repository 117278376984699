import { BrowserRouter as Router } from "react-router-dom";
import { ScrollTop } from "routes/scrollTop";

type AppProviderProps = {
    children: React.ReactNode;
}
export const AppProvider = ({children}: AppProviderProps) => {
    return (
        <Router>
            <ScrollTop />
            {children}
        </Router>
    );
}