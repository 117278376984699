import { Box, TextField } from "@mui/material";
import { FC } from "react";
import { MessageType } from "types/entryTypes";

export const Message: FC <MessageType> = (props) => {
    const {register} = props;
    return(
        <>
            <Box sx={messageContainer}>
                <Box sx={{mr: 3}}>
                    <Box>担当採用者への</Box>
                    <Box sx={{textAlign: "end"}}>メッセージ</Box>
                </Box>
                <TextField multiline rows={5} {...register("recuruitmentOfiicer")} sx={{width: "80%"}}/>
            </Box>
        </>
    );
}

const messageContainer = {
    mt:8,
    ml: 2,
    display: "flex",
}