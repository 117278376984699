import { Box } from "@mui/material";
import { grayLine, textColor } from "components/Color";
import { SingleLine } from "components/Lines/Lines";
import { FC } from "react";

export const NewGraduateRecruit: FC = () => {
    return(
        <>
        <Box sx={main}>
                <Box sx={occupation}>募集職種</Box>
                <Box sx={{mb:3}}>
                    <SingleLine width={"100%"} height={1} backgroundColor={grayLine} />
                </Box>
                <Box sx={{ml: 2, mb: 8, color: textColor}}>現在募集している職種はありません。</Box>
            </Box>
        </>
    );
}
const main = {
    ml: 10,
    mr: 10,
    mb: 4,
    overflow: "auto",
}

const  occupation = {
    mt: 3,
    mb: 3,
    fontSize: 24,
    fontWeight: "bold",
    color: textColor,
}