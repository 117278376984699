import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DayType } from "types/entryTypes";

export const Day: FC<DayType> = (props) => {
	const {register, watch} = props;
	const [days, setDays] = useState<string[]>([]);

	useEffect(() => {
		const year = watch("year") as string;
		const month = watch("month") as string;
		if(year!== "" && month !== "") {
			const newDays = [];
			const date = new Date(parseInt(year),(parseInt(month)), 0).getDate();
			for(let i = 1; i <= date; i++) {
				newDays.push(`${i}`);
			}
			setDays(newDays);
		}
	},[watch("year"), watch("month")]);
	return(
		<>
			<FormControl fullWidth>
				<InputLabel id="day">日</InputLabel>
					<Select id={"day"} labelId={"day"} label={"日"} defaultValue={""} {...register("day")}>
					{days.map((day) => (
						<MenuItem value={day} key={day}>{day}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Box sx={{mt:4, ml:2}}>日</Box>
		</>
	);
} 
