import { FC } from "react";
import { Address, Birthday, Contact, Gender, Name, NameKana  } from "features/entry/components/basicInfomation";
import { Box } from "@mui/material";
import { titleGray } from "components/Color";
import { BasicInfomationType } from "types/entryTypes";
export const BasicInfomation: FC<BasicInfomationType> = (props) => {
	const {register, errors, watch, setValue} = props;
	return(
		<>
			<Box sx={main}>
				<Box sx={basicInfoText}>基本情報</Box>
				<Name register={register} errors={errors}/>
				<NameKana register={register} errors={errors}/>
				<Birthday register={register} watch={watch}/>
				<Gender register={register}/>
				<Contact register={register} errors={errors}/>
				<Address register={register} watch={watch} setValue={setValue}/>
			</Box>
		</>
	);
}

const main = {
	width: "95%",
	m: "0 0 0 auto",
}
const basicInfoText = {
	p:"8px 16px",
	backgroundColor: titleGray,
	fontWeight: "bold",
}