import { Box } from "@mui/material";
import { FC } from "react";
import { FileSelectResult } from "../FileSelectResult";
import { AppDocConfirm } from "types/entryTypes";

export const JobCareer: FC<AppDocConfirm> = (props) => {
	const {watch, setValue} = props;
	return(
		<>
			<Box sx={{mt: 5}}>
				<FileSelectResult label={"　　履歴書"} id={"jobCareerPassCode"} watch={watch}  setValue={setValue}/>
			</Box>
		</>
	);
} 
