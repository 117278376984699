import { Box } from "@mui/material";
import { titleGray } from "components/Color";
import { FC } from "react";
import { ApplicationDocType } from "types/entryTypes";
import { JobCareer, PersonalHistory, Others, Message } from "features/entry/components/applicationDocument";


export const ApplicationDocument: FC<ApplicationDocType> = (props) => {
	const {register, setValue, watch, confirm} = props;
	return(
		<>
			<Box sx={main}>
				<Box sx={applicationDocText}>応募書類等</Box>
				<Box sx={components}>
					<JobCareer setValue={setValue} watch={watch} confirm={confirm}/>
					<PersonalHistory setValue={setValue} watch={watch}  confirm={confirm}/>
					<Others setValue={setValue} watch={watch}  confirm={confirm} />
				</Box>
				<Message register={register}/>
			</Box>
		</>
	);
} 

const main = {
	width: "95%",
	m: "0 0 0 auto",
}
const applicationDocText = {
	p:"8px 16px",
	backgroundColor: titleGray,
	fontWeight: "bold",
}

const components = {
	width: "95%",
	m: "0 0 0 auto"
}
