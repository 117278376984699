import { Box } from "@mui/material";
import { FC } from "react";
import { SingleLineType } from "types/lineTypes";

export const SingleLine: FC<SingleLineType> = (props) => {
    const {width, maxWidth ,height, backgroundColor} = props;
    return (
        <Box sx={{width: {width}, maxWidth: {maxWidth}, height: {height}, backgroundColor:{backgroundColor}}}/>
    );
}
