import { Box } from "@mui/material";
import { FC } from "react";
import { JobCareer, Message, Others, PersonalHistory } from "features/entry/components/applicationDocumentConfirm";
import { titleGray } from "components/Color";
import { ApplicationDocConfirmType } from "types/entryTypes";

export const ApplicationDocumentConfirm: FC<ApplicationDocConfirmType> = (props) => {
	const {watch, setValue} = props;
	return(
		<>
			<Box sx={main}>
				<Box sx={applicationDocText}>応募書類等</Box>
				<Box sx={components}>
					<JobCareer watch={watch} setValue={setValue}/>
					<PersonalHistory watch={watch} setValue={setValue} />
					<Others watch={watch}  setValue={setValue}/>
				</Box>
				<Message />
			</Box>
		</>
	);
} 

const main = {
	width: "95%",
	m: "0 0 0 auto",
}
const applicationDocText = {
	p:"8px 16px",
	backgroundColor: titleGray,
	fontWeight: "bold",
}

const components = {
	width: "95%",
	m: "0 0 0 auto"
}
