import { Box, Button } from "@mui/material";
import {  } from "modules/api/sonareApi";
import { white } from "components/Color";
import { DialogWindow } from "components/Window/Dialog";
import { FormDataContext, GrayButtonProvider } from "providers/entry";
import { FC, useContext, useState, } from "react";
import { SubmitHandler } from "react-hook-form";
import { EntryButtonType } from "types/entryTypes";
import { UseFormValuesType } from "types/validationType";
import { entryForCorporate, entryForApplicant } from "modules/api/sonareApi";
import { persistenceFile } from "modules/api/roceApi";

export const EntryButton: FC<EntryButtonType> = (props) => {
    const {checked, confirm, setConfirm, handleSubmit, setEntryEnd} = props;
    const {formData, setFormData} = useContext(FormDataContext);
    const [telAlertOpen, setTelAlertOpen] = useState<boolean>(false);
    const [emailAlertOpen, setEmailAlertOpen] = useState<boolean>(false); 

    const onClickEntry: SubmitHandler<UseFormValuesType> = (data) => {
        if(data.tel !== data.telConfirm) {
            setTelAlertOpen(true);
            return;
        }
        if(data.email !== data.emailConfirm) {
            setEmailAlertOpen(true);
            return;
        }

        setConfirm(true)
        setFormData(data);
    }

    const onClickSubmit = async () => {
        //formDataの中にpassCodeが入っている
        //永続化してから、sonareAPiのメール送信をする
        try{
            if(formData != null) {
                let jobCareerFileId: string | null = null;
                let personalHistoryFileId: string | null = null;
                let othersFileId: string | null = null;

                if(formData?.jobCareerPassCode as string !== "" && formData?.jobCareerPassCode !== undefined) {
                    const jobCareerResult = await persistenceFile(formData?.jobCareerPassCode as string);
                    jobCareerFileId = jobCareerResult.data.fileId;
                }
                if(formData?.personalHistoryPassCode as string !== "" && formData?.personalHistoryPassCode !== undefined) {
                    const personalHistoryResult = await persistenceFile(formData?.personalHistoryPassCode as string);
                    personalHistoryFileId = personalHistoryResult.data.fileId;
                }
                if(formData?.othersPassCode as string !== "" && formData?.othersPassCode !== undefined) {    
                    const othersResult = await persistenceFile(formData?.othersPassCode as string);
                    othersFileId = othersResult.data.fileId;
                }

                
                const body = {
                    "attributes": [
                        {
                            "lastName": formData.lastName,
                            "firstName": formData.firstName,
                            "lastNameKana": formData.lastNameKana,
                            "firstNameKana": formData.firstNameKana,
                            "birthday": `${formData.year}/${formData.month}/${formData.day}`,
                            "gender": formData.gender,
                            "tel": formData.tel,
                            "email": formData.email,
                            "zipcode": `${formData.leftZipcode}${formData.rightZipcode}`,
                            "prefectureName": formData.prefectureName,
                            "address0": formData.address0,
                            "address1": formData.address1,
                            "address2": formData.address2,
                            "message": formData.recuruitmentOfiicer,
                            "jobCareerFileId": jobCareerFileId,
                            "personalHistoryFileId": personalHistoryFileId,
                            "othersFileId": othersFileId,
                        }
                    ]
                }
                //sonareのメールに送信 企業側、求職者側両方に送る必要がある
                const entryForCorporateResult = await entryForCorporate(body); 
                const entryForApplicantResult = await entryForApplicant(body);
                // //200で送信できた場合は画面遷移してメールを送信しましたと画面遷移
                setEntryEnd(true);
                console.log(entryForCorporateResult);
                console.log(entryForApplicantResult);
            }
        }catch(error) {
            console.log(error);
        }
    }

    const onClickCancel = () => {
        setConfirm(!confirm);
    }

    const closeTelAlert = () => {
        setTelAlertOpen(false);
    }

    const closeEmailAlert = () => {
        setEmailAlertOpen(false);
    }
    return (
        <>
            <Box sx={entryButton}>
                {!confirm ? 
                    checked ?
                        <Button variant={"contained"} onClick={handleSubmit(onClickEntry)} sx={button}>入力内容の確認</Button>
                    :
                        <Button variant={"contained"} disabled sx={button}>入力内容の確認</Button>
                :
                    <>
                        <Button variant={"contained"} sx={button} onClick={onClickSubmit}>応募する</Button>
                        <GrayButtonProvider >
                            <Button variant={"contained"} sx={button} onClick={onClickCancel}>修正する</Button>
                        </GrayButtonProvider>
                    </>
                }
                <DialogWindow title={"電話番号の入力内容が一致しません"} open={telAlertOpen} onClickAccept={closeTelAlert} buttonType={"OkOnly"} sx={{width:400}} />
                <DialogWindow title={"メールアドレスの入力内容が一致しません"} open={emailAlertOpen} onClickAccept={closeEmailAlert} buttonType={"OkOnly"} sx={{width:400}} />
            </Box>
        </>
    );
}

const entryButton = {
    display: "flex",
    justifyContent: "center",
    height: 160,
}

const button = {
    fontSize: 24,
    fontWeight: "bold",
    color: white,
    height: 80,
    width: 240,
    mr:6,
    ml:6,
}