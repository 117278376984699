import { FC } from "react";
import {Box} from "@mui/material";
import { ContainedButton } from "components/Buttons/ContainedButton";
import { Logo } from "components/Images/logo/Logo";
import { textColor } from "components/Color";
import { useFunctions } from "components/Hooks";

//styles
const headerContainer = {
    display: "flex",
    alignItems: "center",
    borderBottom: "solid 1px #707070",
    width: "100vw",
    margin: "0 auto"
}
const headerLeft = {
    display: "flex",
    alignItems: "center",
    width: "90%",
}
const headerRight = {
    width: "10%",
    display: "flex",
    justifyContent: "end",
    mr: 3,
}
export const Header: FC = () => {
    const {screenTransition} = useFunctions();
    return(
        <Box sx={headerContainer}>
            <Box sx={headerLeft}>
                <Box sx={{m: "8px 24px"}}><Logo size={60}/></Box>
                <Box sx={{color: textColor, letterSpacing: "0.05em"}}>RECRUITING SITE</Box>
            </Box>
            <Box sx={headerRight}>
                <ContainedButton buttonName={"ENTRY"} sx={{width: 100, height: 45, fontSize: 18}}  onClickFunction={() => screenTransition("/jobDescription")}/>
            </Box>
        </Box>
    );
}