import { getSonarePubHeaders } from "modules/api/headers";
import { AxiosRequestHeaders } from "axios";
import { sonareAxios } from "lib/axios";

export const getAddress = async (zipcode: string) => {
    const headers = getSonarePubHeaders();
    const result = await sonareAxios.get(`/pub/address/zipcode/${zipcode}`, {headers: headers as AxiosRequestHeaders});
    return result;
}

export const getPrefectures = async () => {
    const headers = getSonarePubHeaders();
    const result = await sonareAxios.get(`/pub/address/prefecture`, {headers: headers as AxiosRequestHeaders});
    return result;
}