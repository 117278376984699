import { FC, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box } from "@mui/material";
import { MonthrType } from "types/entryTypes";

export const Month: FC<MonthrType> = (props) => {
	const {register} = props
	const months = ["1","2","3","4","5","6","7","8","9","10","11","12"];

	return(
		<>
			<FormControl fullWidth>
				<InputLabel id="month">月</InputLabel>
				<Select id={"month"}labelId={"month"} label={"月"} defaultValue={""} {...register("month")}>
					{months.map((month) => (
						<MenuItem value={month} key={month}>{month}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Box sx={{mt:4, ml:2}}>月</Box>
		</>
	);
} 
