import { Box, Button, Checkbox } from "@mui/material";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { ModalWindow } from "components/Window";
import { ChangeEvent, FC, useState } from "react";
import { PersonalInfomationType } from "types/entryTypes";

export const PersonalInfomation: FC<PersonalInfomationType> = (props) => {
    const {checked, setChecked} = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const title = "個人情報の取扱いについての同意書";
    
    const content = 
        <Box sx={{lineHeight: "1.7em"}}>
            <Box>株式会社ローチェ</Box>
            <Box sx={{mt: 3}}>個人情報保護管理者（03-6435-8200）</Box>
            <Box sx={{mt: 3}}>ご提出いただいた個人情報は、採用判断および合否結果のご連絡、採用決定後の各種手続きをおこなう目的で利用します。また、皆様の個人情報を、本人の同意なく第三者に委託・提供することはございません。</Box>
            <Box sx={{mt: 3}}>個人情報の提出は任意です。採用選考に必要な情報（氏名、住所、連絡先、学歴、職歴、保有資格等）を提出されない場合は、採用選考上、不利になる可能性があります。</Box>
            <Box sx={{mt: 3}}>ご提出いただいた個人情報に関して、利用目的の通知、個人情報の開示、訂正、追加、削除、利用停止、消去、第三者提供の停止を求めることができます。これらの請求をおこないたい場合は、下記の窓口までご連絡ください。ただし、選考結果の理由等についてのお問い合わせにはお答えできませんのであらかじめご了承ください。</Box>
            <Box sx={{mt: 3}}>【個人情報お問い合わせ窓口】</Box>
            <Box sx={{mt: 3}}>担当：個人情報保護管理者</Box>
            <Box sx={{mt: 3}}>住所：〒105-0021　東京都港区東新橋2-9-2 パラッツォジーラソーレ10F</Box>
            <Box sx={{mt: 3}}>電話：03-6435-8200</Box>
            <Box sx={{mt: 3}}>なお、ご提出いただいた履歴書等の書類は、採用不採用に関わらず返却いたしません。万が一、不採用の場合には、当社規定により適切に処分いたします。</Box>
            <Box sx={{mt: 3}}>株式会社ローチェ</Box>
        </Box>
    ;
    
    const handleModalOpen = () => setModalOpen(true);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return(
        <>
            <Box sx={personalInfo}>
                <Checkbox checked={checked} onChange={handleChange}  />
                <Button variant={"text"} onClick={handleModalOpen} >個人情報取扱<OpenInNewOutlined sx={{fontSize: 20}}/></Button>
                <ModalWindow open={modalOpen} setOpen={setModalOpen} title={title} content={content}/>
                <Box component={"span"}>について同意する</Box>
            </Box>
        </>
    );
}

const personalInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: 7,
    mb: 3,
}