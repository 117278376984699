import { FC } from "react";
import { Size } from "types/imageTypes";
import { ImgTemplate } from "components/Images/ImgTemplate";
import logo from "assets/roce_logo.png"

export const Logo: FC<Size> = (props) => {
    return(
        <>
            <ImgTemplate imageFilePath={logo} imageExplanation={"logo"} size={props.size} />
        </>
    );
}