import { getSonareHeaders } from "modules/api/headers";
import { AxiosRequestHeaders } from "axios";
import { sonareAxios } from "lib/axios";

//登録してあるファイル情報取得
export const getUploadFileInfo = async (passCode: string) => {
    const headers = getSonareHeaders();
    const result = await sonareAxios.get(`/storage/registration-file-info/${passCode}`, {headers: headers as AxiosRequestHeaders});
    return result;
}
//ファイルをアップロード(未永続化)
export const uploadFile = async (passCode: string, body: FormData) => {
    const headers = getSonareHeaders();
    const result = await sonareAxios.post(`/storage/registration-file/${passCode}`, body, {headers: headers as AxiosRequestHeaders});
    return result;
}

//ファイル削除
export const deleteFile = async (passCode: string) => {
    const headers = getSonareHeaders();
    const result = await sonareAxios.delete(`/storage/registration-file/${passCode}`,{headers: headers as AxiosRequestHeaders});
    return result;
}


