import { getSonareHeaders } from "modules/api/headers";
import { AxiosRequestHeaders } from "axios";
import { roceAxios, sonareAxios } from "lib/axios";

//登録用パスコード取得
export const getRegisterPassCode = async () => {
    const headers = getSonareHeaders();
    const result = await roceAxios.get(`/storage/registration-file-access`, {headers: headers as AxiosRequestHeaders});
    return result;
}

//ファイルの永続化
export const persistenceFile = async (passCode: string) => {
    const headers = getSonareHeaders();
    const result = await roceAxios.patch(`/storage/registration-file/${passCode}`,{}, {headers: headers as AxiosRequestHeaders});
    return result;
}
