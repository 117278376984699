import { Box } from "@mui/material";
import { textColor } from "components/Color";
import { FC } from "react";
import { ADFormatType } from "types/jobDescriptionTypes";

export const AccordionDetailsFormat: FC<ADFormatType> = (props) => {
    const {label, children, labelSx} = props;

    const labelArea = {
        width: "12%",
        ml: 5,
        mr: 7,
        fontSize:18,
        fontWeight: "bold",
        textAlign:"end",
        color: textColor,
        ...labelSx,
    }
    return(
        <>
            <Box sx={main}>
                <Box sx={labelArea}>{label}</Box>
                <Box>{children}</Box>
            </Box>
        </>
    );
}

const main = {
    display: "flex",
    alignItems: "center",
    pt: 4,
    pb: 4,
}

