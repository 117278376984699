import { IconTemplateType } from "types/imageTypes";
import { FC } from "react";

export const ImgTemplate: FC<IconTemplateType> = (props) => {
    const {imageFilePath, imageExplanation, size} = props;
    return(
        <>
            <img src={imageFilePath} alt={imageExplanation} height={size}/>
        </>
    );
}