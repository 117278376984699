import { Box } from "@mui/material";
import { getUploadFileInfo } from "modules/api/sonareApi";
import { FC, useState, useEffect } from "react";
import { FileSelectType } from "types/entryTypes";

export const FileSelectResult: FC<FileSelectType> = (props) => {
	const {label, id, watch, setValue} = props;
	const [fileStatus, setFileStatus] = useState<string>("添付なし");
	const [fileName, setFileName] = useState<string>("");

	const getFileInfo = async () => {
		try {
			if(watch != undefined) {
				const filePassCode = watch(id) as string;
				if(filePassCode !== "") {
					const uploadFileInfo = await getUploadFileInfo(filePassCode);
					setFileStatus("添付あり");
					setFileName(uploadFileInfo.data.fileName);
				}
			}
		}catch(error) {
			if(setValue !== undefined) {
				setFileStatus("添付なし");
				setFileName("");
				setValue(id, "");
			}
			console.log(error);
		}
	}
	useEffect(() => {
		getFileInfo();
	},[]);
	return(
		<>
			<Box sx={fileSelectContainer}>
				<Box component={"span"} sx={{mr: 10}}>{label}</Box>
				<Box component={"span"}>{fileStatus}</Box>
				<Box component={"span"} sx={{ml: 2}}>{fileName}</Box>
            </Box>
		</>
	);
} 

const fileSelectContainer = {
	display: "flex",
	alignItems: "center",
}
