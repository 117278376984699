import { FC } from "react"
import { SquareGrayComponent, SquareOrangeComponent, SquareShadowComponent } from "components/Square"
import { Box } from "@mui/material"

export const FirstSquare: FC = () => {
    // styles
    const squareParent = {
        height: "100vh",
        maxHeight: 800,
        width: "100%",
        position: `relative`,
        left: 200,
        top: 40,
        //回転の基準点
        transformOrigin: "left top", 
        transform: `rotate(45deg)`,
    }
    
    const squareShadow1 = {
        position: "absolute",
        left: 140,
        top: -120,
        zIndex:10,
    }
    const squareShadow2 = {
        position: "absolute",
        left: 40, 
        top: -40,
        zIndex:20,
    }
    
    const squareOrange = {
        position: "absolute",
        left: 80, 
        top: -80,
        zIndex: 30,
    }

    const squareGray = {
        position: "absolute",
        zIndex: 40,
    }


    return (
        <>
            <Box sx={{position: "relative"}}>
                <Box sx={squareParent}>
                    <SquareShadowComponent size={600} squareStyle={squareShadow1} />
                    <SquareShadowComponent size={600} squareStyle={squareShadow2} />
                    <SquareOrangeComponent size={600} squareStyle={squareOrange}/>
                    <SquareGrayComponent size={600} squareStyle={squareGray} />
                </Box>
                <Box sx={{ position: "absolute", top: 322,left: 160, color: "#ffffff", zIndex: 50}}>
                    <Box sx={{ fontSize: 60}}>All FOR CUSTOM<Box component="span" sx={{color: "black"}}>ER</Box></Box>
                    <Box sx={{fontSize: 24}}>全ては、お客様の利益のために。</Box>
                </Box>
            </Box>
        </>
    );
}