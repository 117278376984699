import { FC } from "react";
import { FormFieldRequired } from "features/entry/components/FormFields";
import { TelType } from "types/entryTypes";
import { telErrorsMsg } from "components/Common/validation";

export const Tel: FC<TelType> = (props) => {
	const {register, errors} = props;
	return(
		<>
			<FormFieldRequired id={"tel"} label={"電話番号"} labelWidth={200} required={true} register={register} errors={errors} errorMsg={telErrorsMsg} sx={{mb: 3}} tel={true}/>
			<FormFieldRequired id={"telConfirm"} label={"確認用"} labelWidth={200} required={true} register={register} errors={errors} errorMsg={telErrorsMsg} sx={{mb:5}} tel={true}/>
		</>

	);
} 
