import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { YearType } from "types/entryTypes";

export const Year: FC<YearType> = (props) => {
	const {register} = props; 
	const [years, setYears] = useState<string[]>([""]);

	const create = () => {
		//years作成
		const newYears: string[] = [];
		for(let i = 1900; i <= new Date().getFullYear(); i++) {
			newYears.push(`${i}`)
		}
		setYears(newYears);
	}

	useEffect(() => {
		create();
	},[]);
	return (
		<>
			<FormControl fullWidth>
				<InputLabel id="year">年</InputLabel>
				<Select id={"year"} labelId={"year"} label={"年"} defaultValue={""} {...register("year")}>
					{years.map((year) => (
						<MenuItem value={year} key={year}>{year}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Box sx={{mt:4, ml:2}}>年</Box>
		</>
	);
} 
